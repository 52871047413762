import './App.css';
import { AppBar, Box, createTheme, ThemeProvider, Typography } from '@mui/material';
import DiscordLogin from './components/DiscordLogin';


function Home(props: { user: any, setUser: Function, handleLogOut: Function }) {
  const { user, setUser, handleLogOut } = props

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (

    <ThemeProvider theme={darkTheme}>
      <div className="Home">
        <AppBar className='Appbar' color='secondary' position='sticky'>
          <Typography marginLeft='10px' variant='h2'>XIVReview</Typography>
          <Box position='absolute' right='0px' height='100%'>
            <DiscordLogin handleLogOut={handleLogOut} user={user} />
          </Box>
        </AppBar>
      </div>
    </ThemeProvider >

  );
}

export default Home;
