import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Visibility, Star, StarBorder } from '@mui/icons-material';
import React from 'react';

type Role = "Owner" | "Viewer" | "OP";

type ListItemViewerProps = {
    username: string,
    role: Role,
    avatar?: string,
    isMe?: boolean
};

const ListItemViewer: React.FunctionComponent<ListItemViewerProps> = (props) => {

    const { username, role, avatar, isMe } = props;

    const roleSwitch = (role: Role) => {
        switch (role) {
            case "Owner":
                return <Star />
            case "Viewer":
                return <Visibility />
            case "OP":
                return <StarBorder />
        }
    }

    return (
        <ListItem disablePadding className='ListItemViewer'><ListItemButton>
            <ListItemAvatar><Avatar src={avatar} /></ListItemAvatar>
            <ListItemText><Typography className='ListItemViewerUsername'>{username}</Typography></ListItemText>
            {/*<ListItemIcon style={{ minWidth: 0 }}>{roleSwitch(role)}</ListItemIcon>*/}
        </ListItemButton></ListItem>
    )
}

export default ListItemViewer;