import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getCookie } from "react-use-cookie";
import useWebSocket from "react-use-websocket";
import App, { roomLoader } from "../App";
import Home from "../Home";

function RootRouter() {

    const [user, setUser] = useState<null | any>(null)
    const [socketUrl, setSocketUrl] = useState<null | string>(null)
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { shouldReconnect: () => true });

    const fetchUser = () => {
        fetch(`https://${process.env.REACT_APP_API_URL}/user`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setUser(data)
            });
    }

    const [userToken, setToken, removeToken] = useCookies(['token']);

    const prevToken = useRef(userToken)

    const handleLogOut = (a: Function) => {
        console.log("logging out")
        removeToken("token", { domain: process.env.REACT_APP_DOMAIN });
        a();
    }

    useEffect(() => {
        fetchUser()
        setInterval(() => {
            let getCookieJSON: any = {}
            try {
                getCookieJSON = JSON.parse(getCookie("token"))
            } catch (e) { }
            if (prevToken?.current?.token?.access_token !== getCookieJSON?.access_token) {

                console.log("prev", prevToken?.current?.token?.access_token)
                console.log("getCookieJSON", getCookieJSON?.access_token)
                prevToken.current = getCookieJSON?.access_token
                if (getCookieJSON?.access_token) {
                    setToken("token", getCookieJSON?.access_token, { domain: process.env.REACT_APP_DOMAIN })
                    fetchUser()
                } else {
                    removeToken("token", { domain: process.env.REACT_APP_DOMAIN });
                    setUser(null)
                }
            }
        }, 1000)
    }, [])

    const router = createBrowserRouter([
        {
            path: "/:roomId",
            element: <App user={user} setUser={setUser} setSocketUrl={setSocketUrl} sendMessage={sendMessage} lastMessage={lastMessage} />,
            loader: roomLoader,
        },
        {
            path: "/",
            element: <Home user={user} setUser={setUser} handleLogOut={handleLogOut} />,
        },
    ]);

    return (
        <div className="rootrouter">
            <RouterProvider router={router} />
        </div>
    )

}

export default RootRouter;